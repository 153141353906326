define("discourse/plugins/discourse-custom-topic-lists/discourse/connectors/bread-crumbs-right/custom-topic-lists-dropdown", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomTopicListsDropdown extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    comboBoxOptions = {
      filterable: true,
      none: "custom_topic_lists.dropdown",
      caretDownIcon: "caret-right",
      caretUpIcon: "caret-down",
      headerComponent: "tag-drop/tag-drop-header",
      autoInsertNoneItem: false,
      allowAny: false,
      fullWidthOnMobile: true
    };
    content = (() => this.currentUser?.custom_topic_lists.filter(_ref => {
      let {
        showOnDropdown: showOnDropdown1
      } = _ref;
      return showOnDropdown1;
    }).map(t1 => {
      t1.id = t1.slug;
      return t1;
    }) || [])();
    get value() {
      if (!this.router.currentRoute.params.topicListName) {
        return;
      }
      return this.currentUser.custom_topic_lists.find(list1 => list1.slug === this.router.currentRoute.params.topicListName).slug;
    }
    onInput(slug1) {
      this.router.transitionTo("list", slug1);
    }
    static #_3 = (() => dt7948.n(this.prototype, "onInput", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.currentUser.custom_topic_lists.length}}
          <li class="custom-list-item">
            <ComboBox
              @options={{this.comboBoxOptions}}
              @content={{this.content}}
              @value={{this.value}}
              @onChange={{this.onInput}}
              class="tag-drop list-drop custom-list-dropdown"
            />
          </li>
        {{/if}}
      
    */
    {
      "id": "lKMSMXWv",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"custom_topic_lists\",\"length\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"custom-list-item\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"tag-drop list-drop custom-list-dropdown\"]],[[\"@options\",\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"comboBoxOptions\"]],[30,0,[\"content\"]],[30,0,[\"value\"]],[30,0,[\"onInput\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-custom-topic-lists/discourse/connectors/bread-crumbs-right/custom-topic-lists-dropdown.js",
      "scope": () => [_comboBox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CustomTopicListsDropdown;
});