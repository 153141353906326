define("discourse/plugins/discourse-custom-topic-lists/discourse/initializers/initializer-breadcrumbs-topic-lists", ["exports", "discourse/lib/plugin-api", "I18n"], function (_exports, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "custom-topic-lists-initializer",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        const user = api.getCurrentUser();
        if (!user) {
          return;
        }
        if (!user.custom_topic_lists || user.custom_topic_lists.length === 0) {
          return;
        }
        const customTopicsToShow = user.custom_topic_lists.filter(_ref => {
          let {
            showOnSidebar
          } = _ref;
          return showOnSidebar;
        });
        if (customTopicsToShow.length === 0) {
          return;
        }
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          return class extends BaseCustomSidebarSection {
            name = "custom-topic-lists";
            text = (() => _I18n.default.t("custom_topic_lists.sidebar"))();
            links = (() => customTopicsToShow.map(_ref2 => {
              let {
                icon,
                name,
                slug
              } = _ref2;
              return new class extends BaseCustomSidebarSectionLink {
                name = (() => name)();
                route = "list";
                models = (() => [slug])();
                title = (() => name)();
                text = (() => name)();
                prefixType = "icon";
                prefixValue = (() => icon)();
              }();
            }))();
          };
        });
      });
    }
  };
});