define("discourse/plugins/discourse-custom-topic-lists/discourse/routes/list", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class List extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    async model(data) {
      const topicList = this.currentUser.custom_topic_lists.find(list => list.slug === data.topicListName);
      if (!topicList) {
        return this.router.replaceWith("/404");
      }
      const list = await this.store.findFiltered("topicList", {
        filter: "filter",
        params: {
          q: topicList.query
        }
      });
      list.set("path", topicList.slug);
      return list;
    }
  }
  _exports.default = List;
});